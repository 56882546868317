import { load } from "@loaders.gl/core";
import { BasisLoader, selectSupportedBasisFormat } from '@loaders.gl/textures';
// eslint-disable-next-line import/named
import { BasisFormat } from "@loaders.gl/textures/dist/lib/parsers/parse-basis";
import { MapBuilder } from "../MapBuilder";
import ApiService from "../../api-interaction/ApiService";
import { requestAuthorizationHeaderRemover } from "../helpers/requestAuthorizationHeaderRemover";
import { ImageSpriteInfo } from "../types/ImageSpriteInfo";
import { TextureLevel } from "../types/TextureLevel";
import { MapCreationStep } from "./MapCreationStep";

const supportedFormat = selectSupportedBasisFormat();

let noAlphaFormat: BasisFormat = null;

if (typeof supportedFormat === 'object') {
    noAlphaFormat = supportedFormat.noAlpha;
} else {
    noAlphaFormat = supportedFormat;
}


export class MapReservedAreaSpritesStep extends MapCreationStep {

    constructor (
        metaverseId: number,
        private config: { reservedAreaSpritesHost: string, reservedAreaSpritesPath: string },
        private map: MapBuilder,
        nextStep?: MapCreationStep,
    ) {
        super(metaverseId, nextStep);
    }

    protected async loadFromIndexedDB (): Promise<ImageSpriteInfo[]> {
        const idb = await MapCreationStep.idb;
        const sprites = await idb.getAllFromIndex('reserved-area-sprites', 'metaverseId', this.metaverseId);
        return sprites;
    }

    private async fetchSpritesDirectoryData (): Promise<Record<number, ImageSpriteInfo>> {
        const metaverseId = this.metaverseId;
        const folderPathFromBucketRoot = this.config.reservedAreaSpritesPath;
        const spritesBucketUrl = this.config.reservedAreaSpritesHost;
        const spritesFolderListPath = `${spritesBucketUrl}/?prefix=${folderPathFromBucketRoot}&marker=${folderPathFromBucketRoot}`;
        const response = await ApiService.query(
            spritesFolderListPath,
            { transformRequest: [requestAuthorizationHeaderRemover] },
        );
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, 'text/html');
        const files = doc.querySelectorAll('Contents');
        const sprites = {} as Record<number, ImageSpriteInfo>;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = file.querySelector('Key').innerHTML;
            const spritePath = `${spritesBucketUrl}/${fileName}`;
            const spriteIndex = Number(fileName.replace('.basis', '').replace(folderPathFromBucketRoot, ''));
            const fileSize = Number(file.querySelector('Size').innerHTML);
            const lastModified = file.querySelector('LastModified').innerHTML;
            const id = `${this.metaverseId}-${spriteIndex}`;
            sprites[spriteIndex] = {
                spritePath,
                spriteIndex,
                fileSize,
                lastModified,
                fileName,
                id,
                metaverseId,
            };
        }
        return sprites;
    }

    private findDifferencesWithAPI (apiSprites: Record<number, ImageSpriteInfo>, sprites: ImageSpriteInfo[]): ImageSpriteInfo[] {
        if (!sprites) {
            return Object.values(apiSprites);
        }
        const spritesConvertedToRecord = {} as Record<number, ImageSpriteInfo>;
        for (const sprite of sprites) {
            spritesConvertedToRecord[sprite.spriteIndex] = sprite;
        }
        const spritesToUpdate = [] as ImageSpriteInfo[];
        for (const apiSprite of Object.values(apiSprites)) {
            const localSprite = spritesConvertedToRecord[apiSprite.spriteIndex];
            if (!localSprite ||
                 new Date(localSprite.lastModified).getTime() !== new Date(apiSprite.lastModified).getTime() ||
                 localSprite.fileSize !== apiSprite.fileSize) {
                spritesToUpdate.push(apiSprite);
            }
        }
        return spritesToUpdate;
    }

    private async loadSprite (sprite: ImageSpriteInfo): Promise<ImageSpriteInfo> {
        const basis = await load(sprite.spritePath, BasisLoader, {
            basis: {
                format: noAlphaFormat,
                containerFormat: 'basis',
                module: 'transcoder',
            },
        });
        const texture = basis[0][0] as TextureLevel;
        sprite.texture = texture;
        sprite.updatingPromise = null;
        this.save(sprite);
        return sprite;
    }

    protected async loadFromAPI (localExistingData?: ImageSpriteInfo[]): Promise<ImageSpriteInfo[]> {
        localExistingData = localExistingData || await this.loadFromIndexedDB();
        const apiSprites = await this.fetchSpritesDirectoryData();
        const spritesNeededForUpdate = this.findDifferencesWithAPI(apiSprites, localExistingData);
        if (spritesNeededForUpdate.length === 0) {
            return [];
        }
        for (const sprite of spritesNeededForUpdate) {
            const updatingPromise = this.loadSprite(sprite);
            sprite.updatingPromise = updatingPromise;
            sprite.texture = {
                compressed: false,
                width: 1,
                height: 1,
                webglTexture: null,
                data: new Uint8Array([200, 206, 215, 255]),
            };
        }
        return spritesNeededForUpdate;
    }

    protected process (data: ImageSpriteInfo[]): Record<string, ImageSpriteInfo> {
        return Object.fromEntries(data.map(sprite => [sprite.id, sprite]));
    }

    protected setupOnMap (areaSprites: ImageSpriteInfo[]): void {
        for (const sprite of areaSprites) {
            this.map.addReservedAreaSprite(sprite);
        }
        this.map.redraw();
    }

    protected async save (data: ImageSpriteInfo): Promise<void> {
        const idb = await MapCreationStep.idb;
        idb.put('reserved-area-sprites', data);
    }

    public async restoreFromIndexedDB (): Promise<ImageSpriteInfo[] | null> {
        const sprites = await this.loadFromIndexedDB();
        if (!sprites || sprites.length === 0) {
            return null;
        }
        this.setupOnMap(sprites);
        return sprites;
    }

    public async createFromAPI (localExistingData?: ImageSpriteInfo[]): Promise<ImageSpriteInfo[]> {
        const updatingSprites = await this.loadFromAPI(localExistingData);
        if (updatingSprites.length === 0) {
            return;
        }
        const spritesRecord = Object.fromEntries(
            updatingSprites.map(sprite => [sprite.id, sprite] as [string, ImageSpriteInfo]),
        );
        for (const sprite of (localExistingData || [])) {
            const updatingSprite = spritesRecord[sprite.id];
            if (updatingSprite) {
                updatingSprite.texture = sprite.texture;
            } else {
                spritesRecord[sprite.id] = sprite;
            }
        }
        this.setupOnMap(Object.values(spritesRecord));
        return Promise.all(updatingSprites.map(sprite => sprite.updatingPromise));
    }

    public async executeSelf(): Promise<void> {
        const existingData = await this.restoreFromIndexedDB();
        this.createFromAPI(existingData);
    }

    public execute (): Promise<void> {
        this.executeSelf();
        return this.nextStep?.execute();
    }

}