import ApiService from "~/core/services/api-interaction/ApiService";
import { KYCStatusTypes } from "~/pages/nfts/cards/types/KYCStatusTypes";
import { CashFTCard } from "~/core/models/CashFTCard";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';

export const state = () => {
    return {
        isShowCashFTModal: false,
        showModalCardType: null,
        loadingFormData: false,
        personalInfoFormData: null,
        loadingUploadIDFormData: false,
        uploadIDFormData: null,
        loadingUploadAddressProof: false,
        personalInfoError: null,
        status_address_proof: null,
        status_document_proof: null,
        status_personal_data: null,
        KYCData: null,
        isKYCDataLoading: false,
        uploadIDError: null,
        addressProofError: null,
        isDisableBuyCardBundle: false,
        status_kyc: null,
        error_message: null,
        cardStatus: KYCStatusTypes.NONE,
        cashFTKYCApprovedPopupStatus: false,
        cashFTKYCRejectedPopupStatus: false,
        cardsData: [],
    }
}

export const actions = {
    async postKYCPersonalInformation({ commit }, payload) {
        commit('SET_PERSONAL_INFO_LOADING', true);
        try {
            await ApiService.post('/kyc/user-data', payload);
        } catch (e) {
            commit('SET_PERSONAL_INFO_ERROR', e);
            if (e.code === 62) {
                // Parse the JSON string
                const parsedMessage = JSON.parse(e.message);

                // Check for success status
                if (!parsedMessage.success) {
                    // Iterate through message properties and handle each case
                    if (typeof parsedMessage.message === 'object') {
                        for (const key in parsedMessage.message) {
                            if (Object.hasOwnProperty.call(parsedMessage.message, key)) {
                                const errorMessages = parsedMessage.message[key];
                                errorMessages.forEach(errorMessage => {
                                    // Perform actions based on error message
                                    throw (`Error in ${key}: ${errorMessage}`);
                                });
                            }
                        }
                    } else {
                        if (parsedMessage.message) {
                            throw parsedMessage.message
                        } else {
                            throw parsedMessage;
                        }
                    }
                }
            }
            throw e;
        } finally {
            commit('SET_PERSONAL_INFO_LOADING', false);
        }
    },
    async getKYCData({ commit }, {isKYCPage = false, isClickedOnBuyBtn = false}) {
        commit('SET_GET_KYC_DATA_LOADING', true);
        try {
            const result = await ApiService.get('/kyc/get-data');
            commit('SET_KYC_GET_DATA', { data: result?.data, isKYCPage, isClickedOnBuyBtn});
        } catch (e) {
            console.log(e)
            // commit('SET_PERSONAL_INFO_ERROR', true);
        } finally {
            commit('SET_GET_KYC_DATA_LOADING', false);
        }
    },
    async postKYCUploadId({ commit }, payload) {
        commit('SET_UPLOAD_ID_LOADING', true);
        try {
            await ApiService.post('/kyc/document-proof', payload);
        } catch (e) {
            commit('SET_UPLOAD_ID_ERROR', e);
            if (e.code === 62) {
                // Parse the JSON string
                const parsedMessage = JSON.parse(e.message);

                // Check for success status
                if (!parsedMessage.success) {
                    // Iterate through message properties and handle each case
                    if (typeof parsedMessage.message === 'object') {
                        for (const key in parsedMessage.message) {
                            if (Object.hasOwnProperty.call(parsedMessage.message, key)) {
                                const errorMessages = parsedMessage.message[key];
                                errorMessages.forEach(errorMessage => {
                                    // Perform actions based on error message
                                    throw (`Error in ${key}: ${errorMessage}`);
                                });
                            }
                        }
                    } else {
                        if (parsedMessage.message) {
                            throw parsedMessage.message
                        } else {
                            throw parsedMessage;
                        }
                    }
                }
            }
            throw e;
        } finally {
            commit('SET_UPLOAD_ID_LOADING', false);
        }
    },
    async postUploadIDData({ commit }, payload){
        commit('SET_UPLOAD_ADDRESS_PROOF_LOADING', true);
        try {
            await ApiService.post('/kyc/address-proof', payload)
        } catch (e) {
            commit('SET_UPLOAD_ADDRESS_PROOF_ERROR', e);
            if (e.code === 62) {
                // Parse the JSON string
                const parsedMessage = JSON.parse(e.message);

                // Check for success status
                if (!parsedMessage.success) {
                    // Iterate through message properties and handle each case
                    if (typeof parsedMessage.message === 'object') {
                        for (const key in parsedMessage.message) {
                            if (Object.hasOwnProperty.call(parsedMessage.message, key)) {
                                const errorMessages = parsedMessage.message[key];
                                errorMessages.forEach(errorMessage => {
                                    // Perform actions based on error message
                                    throw (`Error in ${key}: ${errorMessage}`);
                                });
                            }
                        }
                    } else {
                        if (parsedMessage.message) {
                            throw parsedMessage.message
                        } else {
                            throw parsedMessage;
                        }
                    }
                }
            }
            throw e;
        } finally {
            commit('SET_UPLOAD_ADDRESS_PROOF_LOADING', false);
        }
    },
    async getCardStatus({ commit, state }) {
        try {
            const result = await ApiService.get('/kyc/get-data');
            commit('SET_CARD_STATUS', result?.data?.status_kyc || KYCStatusTypes.NONE);
            if (result?.data && result?.data?.status_kyc === KYCStatusTypes.REJECTED && result?.data?.is_seen_rejected === false) {
                commit('SET_REJECTED_POPUP_SEEN', true);
                commit('SET_REJECTED_ERROR_MESSAGE', result?.data?.error_message);
            }
            if (
                result?.data &&
                result?.data?.status_kyc === KYCStatusTypes.APPROVED &&
                result?.data?.is_seen === false &&
                !state.cardsData.length
            ) {
                commit('SET_APPROVED_POPUP_SEEN', true);
            }
        } catch (e) {
            commit('SET_CARD_STATUS', KYCStatusTypes.NONE);
        }
    },
    async setCashFTKYCApprovedPopupSeen({ commit }) {
        commit('SET_APPROVED_POPUP_SEEN', false);
        try {
            await ApiService.post('/kyc/approved-seen', { type: KYCStatusTypes.APPROVED });
        } catch (e) {
            console.log(e);
        }
    },
    async setCashFTKYCRejectedPopupSeen({ commit }) {
        commit('SET_REJECTED_POPUP_SEEN', false);
        try {
            await ApiService.post('/kyc/approved-seen', { type: KYCStatusTypes.REJECTED });
        } catch (e) {
            console.log(e);
        }
    },
    async setCashFTTermsAndConditionsPopupSeen({ dispatch }) {
        await ApiService.post('/kyc/approved-seen', { type: 'cashftTerms' });
        await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            isCashFtTermsAndConditionsAccepted: true,
        }, {root: true});
    },
    async getCashFTCards({ commit }) {
        const result = await ApiService.get('/kyc/get-user-cards');
        commit('SET_CASHFT_CARDS', result.data);
    },
    async activateCashFTCard({ commit }, { pin, cardKitNumber, cardId }) {
        return await ApiService.post('/kyc/activate-card', { pin, cardKitNumber, cardId });
    }
}

export const mutations = {
    // personal information
    SET_CASHFT_SHOW_MODAL_STATUS(state, value) {
        state.isShowCashFTModal = value;
    },
    SET_CASHFT_SHOW_MODAL_CARD_TYPE(state, value) {
        state.showModalCardType = value;
    },
    SET_PERSONAL_INFO_LOADING(state, value) {
        state.loadingFormData = value;
    },
    SET_PERSONAL_INFO_DATA(state, data) {
        state.personalInfoFormData = data;
    },
    // end personal information

    // upload ID
    SET_UPLOAD_ID_LOADING(state, value) {
        state.loadingUploadIDFormData = value;
    },
    SET_UPLOAD_ID_DATA(state, data) {
        state.uploadIDFormData = data;
    },
    // end Upload ID

    // upload address proof
    SET_UPLOAD_ADDRESS_PROOF_LOADING(state, value) {
        state.loadingUploadAddressProof = value;
    },
    // end upload address proof

    SET_PERSONAL_INFO_ERROR(state, data) {
        state.personalInfoError = data?.message;
    },
    SET_KYC_GET_DATA(state, data) {
        state.KYCData = data.data;
        state.status_address_proof = data.data.status_address_proof;
        state.status_document_proof = data.data.status_document_proof;
        state.status_personal_data = data.data.status_personal_data;
        state.status_kyc = data.data?.status_kyc;
        state.error_message = data.data?.error_message;
        if (!data.isKYCPage && data.isClickedOnBuyBtn) {
            state.isShowCashFTModal = !data.data.status_personal_data || !data.data.status_document_proof || !data.data.status_address_proof;
        }
        state.isDisableBuyCardBundle = data.data.status_personal_data === 'pending' && data.data.status_document_proof === 'pending' && data.data.status_address_proof === 'pending';
    },
    SET_GET_KYC_DATA_LOADING(state, value) {
        state.isKYCDataLoading = value;
    },
    SET_UPLOAD_ID_ERROR(state, data) {
        state.uploadIDError = data?.message;
    },
    SET_UPLOAD_ADDRESS_PROOF_ERROR(state, data) {
        state.addressProofError = data?.message;
    },

    // card product page
    SET_CARD_STATUS(state, data) {
        state.cardStatus = data;
    },
    SET_APPROVED_POPUP_SEEN(state, data) {
        state.cashFTKYCApprovedPopupStatus = data;
    },
    SET_REJECTED_POPUP_SEEN(state, data) {
        state.cashFTKYCRejectedPopupStatus = data;
    },
    SET_REJECTED_ERROR_MESSAGE(state, data) {
        state.error_message = data;
    },
    SET_CASHFT_CARDS(state, data) {
        let cards = [];
        data.forEach(card => {
            cards.push(new CashFTCard(card));
        });
        state.cardsData = cards.sort((a, b) => {
            if (a.isDeactivated && !b.isDeactivated) {
                return 1;
            } else if (!a.isDeactivated && b.isDeactivated) {
                return -1;
            } return 0;
        });
    },
    SET_CASHFT_CARDS_STATUS(state, id) {
        state.cardsData.forEach(card => {
            if (card.cardId === id) {
                card.setStatus();
            }
        })
    },
}

export const getters = {
    isShowCashFTModal: ({ isShowCashFTModal }) => isShowCashFTModal,
    showModalCardType: ({ showModalCardType }) => showModalCardType,
    loadingFormData: ({ loadingFormData }) => loadingFormData,
    personalInfoFormData: ({ personalInfoFormData }) => personalInfoFormData,
    loadingUploadIDFormData: ({ loadingUploadIDFormData }) => loadingUploadIDFormData,
    uploadIDFormData: ({ uploadIDFormData }) => uploadIDFormData,
    loadingUploadAddressProof: ({ loadingUploadAddressProof }) => loadingUploadAddressProof,
    personalInfoError: ({ personalInfoError }) => personalInfoError,
    status_address_proof: ({ status_address_proof }) => status_address_proof,
    status_document_proof: ({ status_document_proof }) => status_document_proof,
    status_personal_data: ({ status_personal_data }) => status_personal_data,
    KYCData: ({ KYCData }) => KYCData,
    isKYCDataLoading: ({ isKYCDataLoading }) => isKYCDataLoading,
    uploadIDError: ({ uploadIDError }) => uploadIDError,
    addressProofError: ({ addressProofError }) => addressProofError,
    isDisableBuyCardBundle: ({ isDisableBuyCardBundle }) => isDisableBuyCardBundle,
    status_kyc: ({ status_kyc }) => status_kyc,
    error_message: ({ error_message }) => error_message,
    cardStatus: ({ cardStatus }) => cardStatus,
    cashFTKYCApprovedPopupStatus: ({ cashFTKYCApprovedPopupStatus }) => cashFTKYCApprovedPopupStatus,
    cashFTKYCRejectedPopupStatus: ({ cashFTKYCRejectedPopupStatus }) => cashFTKYCRejectedPopupStatus,
    cardsData: ({ cardsData }) => cardsData,
}
