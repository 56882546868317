import { render, staticRenderFns } from "./PurchaseRequirementsPopup.vue?vue&type=template&id=755a7af6"
import script from "./PurchaseRequirementsPopup.vue?vue&type=script&lang=ts&setup=true"
export * from "./PurchaseRequirementsPopup.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,FlowsCardsPopupTemplate: require('/root/build_gs/core/components/flows/cards/PopupTemplate.vue').default})
