import moment from "moment";

export const RaffleEndDate = 1741377599000; // Friday, March 7, 2025 23:59:59 GMT+04:00
export const RaffleStreamDate = 1741608000000; // Monday, March 10, 2025 4:00:00 PM GMT+04:00
export const RaffleStreamEndDate = 1741611600000; // Monday, March 10, 2025 5:00:00 PM GMT+04:00

export function isRaffleActive() {
    const raffleEndDateUTC = moment(RaffleEndDate).utcOffset(4 * 60).utc().valueOf();

    // Get the current UTC time
    const currentTimeUTC = moment().utc().valueOf();

    // Return true if the raffle is still active (current time is before the end date)
    return currentTimeUTC < raffleEndDateUTC;
}

export function shouldShowLiveStreamButton() {
    const liveStreamTimeUTC = moment(RaffleStreamDate).utcOffset(4 * 60).utc().valueOf();

    // Get the current UTC time
    const currentTimeUTC = moment().utc().valueOf();

    // Show button if the current UTC time is equal to or past the live stream time
    return currentTimeUTC >= liveStreamTimeUTC;
}

export function shouldHideRaffleButtons() {
    const streamEndTimeUTC = moment(RaffleStreamEndDate).utcOffset(4 * 60).utc().valueOf();

    // Get the current UTC time
    const currentTimeUTC = moment().utc().valueOf();

    // Hide buttons if live stream ended
    return currentTimeUTC >= streamEndTimeUTC;
}
