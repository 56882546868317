import { GETTERS as METAVERSE_GETTERS } from "../metaverse/names";
import { GETTERS, STATE } from "./names";
import { State } from "./types";
import { getLastDayOfMonth } from "./helpers";
import { PromoItemType, PromosNamesEnum } from "~/store/affility/promos/types";
import { METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";
import { RaffleEndDate } from "~/pages/promos/raffle/configs/RaffleConfig";

export default {
    [GETTERS.PROMOS]:(state: State, _getters, _rootState, rootGetters) => {
        const metaverseId = rootGetters[`affility/metaverse/${METAVERSE_GETTERS.ID}`];
        return state[STATE.PROMOS].filter(promo => promo.metaverseIds?.includes(metaverseId));
    },
    [GETTERS.PROMOS_LOADING](state: State) {
        return state[STATE.PROMOS_LOADING];
    },
    [GETTERS.PROMOS_DATA_CONFIG]: (_state, _getters, _rootState): PromoItemType [] => {
        return  [
            {
                id: PromosNamesEnum.RAFFLE,
                promoImage: require('~/assets/affility/images/promos/raffle.webp'),
                href: '/promos/raffle',
                title: 'raffle',
                expiresAt: RaffleEndDate,
                rewards: null,
                isLoopedPromo: false,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.BTC_POOL,
                promoImage: require('~/assets/affility/images/promos/btc-pool.webp'),
                href: '/promos/btc-pool',
                title: 'btc_pool',
                expiresAt: getLastDayOfMonth(),
                rewards: null,
                isLoopedPromo: true,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.GLOBAL_POOL,
                promoImage: require('~/assets/affility/images/promos/global-pool.webp'),
                href: '/partner-area/incentives/global-pool',
                title: 'Global Pool',
                expiresAt: getLastDayOfMonth(),
                rewards: null,
                isLoopedPromo: true,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.PRODUCT_AUTOSHIP,
                promoImage: require('~/assets/affility/images/promos/product-autoship-incentive.webp'),
                href: '/partner-area/incentives/product-autoship-incentive',
                title: 'Product Autoship',
                expiresAt: getLastDayOfMonth(),
                rewards: null,
                isLoopedPromo: true,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.RANK_ACHIEVEMENT,
                promoImage: require('~/assets/affility/images/promos/rank-achievement-bonus.webp'),
                href: '/partner-area/career-status',
                title: 'Rank Achievement Bonus',
                expiresAt: null,
                rewards: null,
                isLoopedPromo: true,
                noExpiresDate: true,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.THIRD_ANNIVERSARY,
                promoImage: require('~/assets/affility/images/promos/3rd-anniversary.webp'),
                href: '/partner-area/incentives/third-anniversary-promo',
                title: '3rd Anniversary',
                expiresAt: 1730419199000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.DIRECT_SALES,
                promoImage: require('~/assets/affility/images/promos/direct-sales-incentive.webp'),
                href: '/partner-area/incentives/direct-sales-incentive',
                title: 'Direct Sales Incentive',
                expiresAt: 1727740800000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.TRAVEL,
                promoImage: require('~/assets/affility/images/promos/travel.webp'),
                href: '/partner-area/incentives/travel-incentive',
                title: 'Travel Incentive',
                expiresAt: 1718481599000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.TURNOVER,
                promoImage: require('~/assets/affility/images/promos/turnover.webp'),
                href: '/partner-area/incentives/turnover-incentive',
                title: 'Turnover Incentive',
                expiresAt: 1711880610000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.FAST_START,
                promoImage: require('~/assets/affility/images/promos/fast-start-incentive.webp'),
                href: '/partner-area/incentives/fast-start',
                title: 'Fast Start Incentive',
                expiresAt: 1709809200000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.SPRING_INCENTIVE,
                promoImage: require('~/assets/affility/images/promos/spring.webp'),
                href: '/partner-area/incentives/spring-incentive',
                title: 'Spring Incentive',
                expiresAt: 1718481599000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.CASH_PROMO,
                promoImage: require('~/assets/affility/images/promos/cash-promo.webp'),
                href: '/partner-area/incentives/cash-promo',
                title: 'Cash Promo',
                expiresAt: 1709197200000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.LEVEL_POOL,
                promoImage: require('~/assets/affility/images/promos/level-pool.webp'),
                href: '/partner-area/level-pool',
                title: 'Level pool',
                expiresAt: null,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.TURNOVER_POOL,
                promoImage: require('~/assets/affility/images/promos/turnover-pool.webp'),
                href: '/partner-area/turnover-pool',
                title: 'Turnover pool',
                expiresAt: null,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.GLOBAL_LINE_BONUS,
                promoImage: require('~/assets/affility/images/promos/blobal-line-bonus.webp'),
                href: '/partner-area/global-line-bonus',
                title: 'Global Line Bonus',
                expiresAt: null,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.KABUTOCHO,METAVERSE_IDS.GYMSTREET],
            },
            {
                id: PromosNamesEnum.MYSTERY_BOX,
                promoImage: require('~/assets/affility/images/promos/mystery-box.webp'),
                href: '/promos/mystery-box',
                title: 'Mystery Box',
                expiresAt: 1710846000000,
                rewards: null,
                metaverseIds:[METAVERSE_IDS.GYMSTREET],
            },
        ];
    },
};
