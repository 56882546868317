import { ParcelType } from "../constants/EnumParcelType";
import { RowImageData } from "../types/RowImageData";
import { ParcelOwner } from "../models/ParcelOwner";
import { MetaReservedArea } from "./MetaReservedArea";
import { RawOwnedParcelData } from "../types/RawOwnedParcelData";

export class MetaParcel {
    public type: ParcelType = ParcelType.UNKNOWN;

    // eslint-disable-next-line no-useless-constructor
    constructor (
        public x: number,
        public y: number,
        public reservedArea?: MetaReservedArea,
        public owner?: ParcelOwner,
        public image?: RowImageData,
    ) { }

    get isReserved (): boolean {
        return !!this.reservedArea;
    }

    public toRawOwned(owner?: ParcelOwner): RawOwnedParcelData {
        if (!owner && !this.owner) {
            throw new Error('Owner is required');
        }
        return {
            x: this.x,
            y: this.y,
            id: this.owner?.id || owner?.id || 0,
            wallet_address: this.owner?.walletAddress || owner?.walletAddress || '',
        };
    }

    public claimBy(newOwner: ParcelOwner, strict: boolean = false): void {
        if (this.owner) {
            if (strict && this.owner !== newOwner) {
                throw new Error('Parcel already has an owner');
            }
            this.owner.disownParcelUnsafe(this);
        }
        newOwner.ownParcelUnsafe(this);
    }

    public release(strict: boolean = false, owner?: ParcelOwner): void {
        if (strict) {
            if (!this.owner) {
                throw new Error('Parcel has no owner');
            }
            if (!owner) {
                throw new Error('Owner is required');
            }
        }
        if (owner && this.owner !== owner) {
            throw new Error('Parcel is not owned by this owner');
        }
        this.owner?.disownParcelUnsafe(this);
    }
}