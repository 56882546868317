import { STATE, GETTERS } from "./names";

export default {
    [GETTERS.RAFFLE_TICKETS]: (state) => state[STATE.RAFFLE_TICKETS],
    [GETTERS.RAFFLE_TICKETS_LOADING]: (state) => state[STATE.RAFFLE_TICKETS_LOADING],
    [GETTERS.RAFFLE_CONGRATS_POPUP_VISIBLE]: (state) => {
        return state[STATE.RAFFLE_TICKETS]?.some(ticket => !ticket.isAcknowledged && ticket.isWinning);
    },
    [GETTERS.RAFFLE_WINNERS]: (state) => state[STATE.RAFFLE_WINNERS],
    [GETTERS.RAFFLE_ACTIVE_STATUS]: (state) => state[STATE.RAFFLE_ACTIVE_STATUS],
    [GETTERS.IS_RAFFLE_ACTIVE]: (state) => state[STATE.RAFFLE_ACTIVE_STATUS]?.isActive,
    [GETTERS.IS_STREAM_STARTED]: (state) => state[STATE.RAFFLE_ACTIVE_STATUS]?.isStreamStarted,
    [GETTERS.IS_STREAM_ENDED]: (state) => state[STATE.RAFFLE_ACTIVE_STATUS]?.isStreamEnded,
}
