
import { mapGetters} from "vuex";
import GymNewButton from "@/components/utils/form-components/GymNewButton";
import { isMobileDevice, toWei } from "~/core/helpers/GlobalHelpers";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "~/store/affility/user/feedback/modals/full-loader/names";

export default {
    name: "AllowanceModal",
    components: {GymNewButton},
    props: {
        showPopup: Boolean,
        popupData: {
            type: Object,
            required: false,
        },
        isSwapAllowance: {
            type: Boolean,
            default: false,
        },
        contractName: {
            type: String,
            default: 'USDT',
        },
    },
    data () {
        return {
            reRenderComponent: false,
            isMobile: isMobileDevice(),
            resizeHandler: () => this.isMobile = isMobileDevice(),
            disableBuyBtn: true,
            active: 0,
        };
    },
    computed: {
        ...mapGetters({
            isSendTxLoading: 'application/driver/isSendTxLoading',
            isSendTxSuccess: 'application/driver/isSendTxSuccess',
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
        tokenName () {
            const contractToTokenMap = {
                GYMNET: 'GymNetwork',
                BTC: 'BTCB',
            };
            return contractToTokenMap[this.popupData?.contractName] || this.popupData?.contractName;
        },
        dynamicTokenName () {
            return this.popupData?.dynamicTokenName || null;
        },
        allowanceForSwap () {
            return this.popupData?.isSwapComponent || false;
        },
        isSubscriptionComponent () {
            return this.popupData?.isSubscription || false;
        },
        isDepositToSP () {
            return this.popupData?.isDepositToSPComponent || false;
        },
        isDepositToSPForCards () {
            return this.popupData?.isDepositToSPForCards || false;
        },
        buttonText() {
            if (this.allowanceForSwap) {
                return this.$t('Swap');
            }
            if (this.isDepositToSP) {
                return this.$t('Deposit');
            }
            return this.$t('Buy');
        },
    },
    watch: {
        showPopup: {
            handler (value) {
                if (value) {
                    window.addEventListener('resize', this.resizeHandler);
                    this.isMobile = isMobileDevice();
                } else {
                    window.removeEventListener('resize', this.resizeHandler);
                }
            },
            immediate: true,
            deep: true,
        },
        isSendTxLoading: {
            async handler (value) {
                if (value) {
                    this.reRenderComponent = value;
                    this.openFullScreenPopup();
                } else {
                    this.closeFullScreenPopup();
                }
            },
        },
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        openFullScreenPopup() {
            this.$store.dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.OPEN_FULL_PAGE_LOADING}`);
        },
        closeFullScreenPopup() {
            this.$store.dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.CLOSE_FULL_PAGE_LOADING}`);
        },
        async handleContinueBuy () {
            if (this.allowanceForSwap) {
                await this.$store.dispatch('application/popup-control/showConfirmSwap', true);
            } else if(this.isSubscriptionComponent) {
                await this.$store.dispatch('application/popup-control/showWeb2SubscriptionPopup', true);
            } else if(this.isDepositToSP) {
                await this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopupLockedUnlocked', true);
            } else if (this.isDepositToSPForCards) {
                await this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopup', true);
            } else {
                await this.$store.dispatch('application/popup-control/showWeb2Popup', true);
            }
            this.$store.dispatch('application/popup-control/showWeb2AllowancePopup', false, {root: true});
        },
        async handleConfirm () {
            this.openFullScreenPopup();

            const allowanceAmount = this.popupData.allowancePrice
                ? toWei(String(2 * this.popupData.allowancePrice))
                : 1e24.toLocaleString('fullwide', {useGrouping:false});

            const transactionObject = {
                to: this.tokenName ? this.$contracts[this.metaverseId][this.tokenName]._address : this.$contracts[this.metaverseId][this.contractName]._address,
                value: 0,
                data: this.$web3().eth.abi.encodeFunctionCall({
                    name: "approve",
                    inputs: [
                        {
                            internalType: "address",
                            name: "spender",
                            type: "address",
                        },
                        {
                            internalType: "uint256",
                            name: "amount",
                            type: "uint256",
                        },
                    ],
                    type: "function",
                }, [this.popupData.spenderAddress, allowanceAmount]),
            };

            let res = await this.$store.dispatch('application/driver/internalWalletSendTx', transactionObject);

            this.closeFullScreenPopup();

            if (res) {
                this.disableBuyBtn = false;
                this.active ++;
            } else {
                this.disableBuyBtn = true;
            }
        },
    },
};
