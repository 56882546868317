import { ACTIONS, MUTATIONS } from "./names";
import ApiService from "~/core/services/api-interaction/ApiService";
import { RaffleStatusType } from "~/store/affility/promos/raffle/types";

export default {
    async [ACTIONS.GET_RAFFLE_TICKETS]({ commit }) {
        try {
            const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/raffle/tickets`);
            commit(MUTATIONS.SET_RAFFLE_TICKETS, response?.data?.data || []);
        } catch (error) {
            console.log(error);
            commit(MUTATIONS.SET_RAFFLE_TICKETS, []);
            throw new Error(error);
        } finally {
            commit(MUTATIONS.SET_RAFFLE_TICKETS_LOADING, false);
        }
    },
    async [ACTIONS.ON_SAVE_RAFFLE_TICKETS_POPUP]({ _ }, payload: string[]) {
        return await ApiService.patch(
            `${this.$config.binaryTreeApi}/api/raffle/tickets/acknowledge`,
            { tickets: payload }
        );
    },
    [ACTIONS.ON_RAFFLE_TICKETS_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_RAFFLE_TICKETS_LOADING, value);
    },
    [ACTIONS.ON_RAFFLE_ACTIVE_STATUS]({ commit }, payload: RaffleStatusType) {
        commit(MUTATIONS.SET_RAFFLE_ACTIVE_STATUS, payload);
    },
    async [ACTIONS.GET_RAFFLE_WINNERS]({ commit }) {
        try {
            const response = await ApiService.post(`${this.$config.binaryTreeApi}/api/raffle/winners`, {});
            commit(MUTATIONS.SET_RAFFLE_WINNERS, response?.data?.winners || []);
        } catch (error) {
            console.log(error);
            commit(MUTATIONS.SET_RAFFLE_WINNERS, []);
            throw new Error(error);
        }
    }
};
