import copy from 'copy-to-clipboard';
import Web3 from "web3";
const web3 = new Web3();

export const toFixedAmount = (value = 0) => {
    if (!value) {
        return;
    }
    return value.toFixed(2);
};

export const toCapitalize = (string) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const copyToClipboard = (copyText: string) => {
    copy(copyText);
};

export const getHidedAccountAddress = (str: string): string => {
    if (!str || str.length !== 42) return '';
    let result = '';
    result = `${str.substring(0, 6)}...${str.substring(str.length - 4)}`;

    return result;
};

export const hideStringData = (str: string, start = 5, end = 5): string => {
    if (!str) return '';
    if (str.length < start + end) return str;
    return `${str.substring(0, start)}...${str.substring(str.length - end)}`;
};

export const getPlatform = (): string | null => {
    let OS = null as string | null;
    const agent = navigator.userAgent;

    if (agent.includes("Win")) OS = PlatformEnum.WEB;
    if (agent.includes("Mac")) OS = PlatformEnum.WEB;
    if (agent.includes("Linux")) OS = PlatformEnum.WEB;
    if (agent.includes("Android")) OS = PlatformEnum.ANDROID;
    if (agent.includes("iPhone")) OS = PlatformEnum.IOS;

    return OS;
};

export const isTouchSupported = (): boolean => {
    return ("ontouchstart" in window) ||
          (navigator.maxTouchPoints > 0) ||
          ((navigator as any).msMaxTouchPoints > 0);
};

const mobileScreenLimit = 500;
export const isMobileDevice = (mobileLimit: number = null): boolean => mobileLimit && window.innerWidth <= mobileLimit || !mobileLimit && window.innerWidth <= mobileScreenLimit;

/*
export const sortArray = (array: Array<any>, key: string) => {
    return array.sort((arr1, arr2) => arr1[key] - arr2[key]);
};
*/
/*
export const reformatDate = (date: any, format = 'DD/MM/YYYY') => {
    let res;
    if(date == 0 || date == undefined || date == null) {
        res = "-";
    } else {
        const dt = new Date(Number(date) * 1000);
        const yyyy = dt.getFullYear();
        let mm: any = dt.getMonth() + 1; // Months start at 0!
        let dd: any = dt.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedDay = dd + '/' + mm + '/' + yyyy;

        res = formattedDay;
    }

    // console.log('reformatDate => ', `input: ${date}`, `outPut: ${res}`)
    return res;
};
*/
/*
export const createAndOpenLink = (link: string, target: string | null = null): void => {
    const a = document.createElement("a");
    a.href = link;

    if (target) a.target = target;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
*/
export enum PlatformEnum {
    WEB = "WEB",
    IOS = "IOS",
    ANDROID = "ANDROID"
}

/**
 *
 * @param translationsJson full translations json
 * @param compareWith with which translation compare
 * @param extension extension of generating file
 */

export const getTranslationsDiffs = async (translationsJson: any, compareWith = 'en', extension = 'txt'): Promise<any> => {
    const diffs = {} as any;
    const selectedTranslation = translationsJson[compareWith];

    delete translationsJson[compareWith];

    for (const keyEN in selectedTranslation) {
        for (const trKey of Object.keys(translationsJson)) {
            const translations = translationsJson[trKey];

            if (!translations[keyEN]) {
                if (!diffs[trKey]) {
                    diffs[trKey] = {};
                }
                diffs[trKey][keyEN] = selectedTranslation[keyEN];
            }
        }
    }

    let reportText = ``;
    if (Object.keys(diffs).length > 0) {
        reportText = `Checked translations for => ${Object.keys(translationsJson)}\n Generated file(s) for `;
        for (const locale of Object.keys(diffs)) {
            const data = diffs[locale];

            reportText += ` /${locale} => ${Object.keys(data).length} records`;
            await createAndDownloadTxtFile(data, locale + '_diffs', extension);
            // await createAndDownloadTxtFile(Object.keys(data), 'keys_' + locale, 'txt');
            // await createAndDownloadTxtFile(Object.values(data), 'values_' + locale, 'txt');
        }
    } else {
        reportText = 'No Diffs Found';
    }

    console.warn(reportText);
};

export const createAndDownloadTxtFile = async (data: any, fileName: string, extension = 'txt'): Promise<void> => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(
        new Blob(
            [JSON.stringify(data, null, 2)],
            {type: "text/plain"}
        ));
    a.setAttribute("download", `${fileName}.${extension}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const parseToDateTimeView = (seconds: number) => {
    let hours = 0;
    let minutes = 0;
    let sec = 0;

    hours = Math.floor(seconds / 3600);
    minutes = Math.floor((seconds - 3600 * hours)/60);
    sec = Math.floor(seconds - minutes * 60 - hours * 3600);

    const hoursOfYear = 365 * 30 * 24;
    const hoursOfMonth = 30 * 24;
    const hoursOfWeek = 7 * 24;
    const hoursOfDay = 24;

    const res = [];

    if (!hours && minutes < 10) {
        if (minutes) res.push({value: minutes, text: minutes > 1 ? 'minutes' : 'minute'});
        if (sec) res.push({value: sec, text: sec > 1 ? 'seconds' : 'second'});
        else res.push({value: '', text: 'Just now'});
    } else if (hours > hoursOfYear) {
        const val = Math.floor(hours / hoursOfYear);
        res.push({value: val, text: val > 1 ? 'years' : 'year'});
    } else if (hours > hoursOfMonth) {
        const val = Math.floor(hours / hoursOfMonth);
        res.push({value: val, text: val > 1 ? 'months' : 'month'});
    } else if (hours > hoursOfWeek) {
        const val = Math.floor(hours / hoursOfWeek);
        res.push({value: val, text: val > 1 ? 'weeks' : 'week'});
    } else if (hours >= hoursOfDay) {
        const val = Math.floor(hours / hoursOfDay);
        res.push({value: val, text: val > 1 ? 'days' : 'day'});
    } else if (hours >= 1) {
        res.push({value: hours, text: hours > 1 ? 'hours' : 'hour'});
    } else {
        res.push({value: minutes, text: 'minutes'});
    }
    res[res.length - 1].text += ' ago';

    return res;
};

export const ceilTo = (value: any, digits = 6, invert = false) => {
    const pow = invert ? 1 / (10 ** digits) : 10 ** digits;
    return Math.ceil(parseFloat(value) * pow) / pow;
};

export const floorTo = (value: any, digits = 5, invert = false) => {
    const pow = invert ? 1 / (10 ** digits) : 10 ** digits;
    return Math.floor(parseFloat(value) * pow) / pow;
};

export const toNumericFormat = (value: any, minDigits = 2, emptyVal = '0.00',type='visual') => {
    if (!value) {
        return emptyVal;
    }

    if(type == 'visual'){
        return parseFloat(value).toFixed(minDigits).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }else {
        return parseFloat(value).toFixed(4);
    }
    // return (Math.floor(parseFloat(value) * 100) / 100).toLocaleString('en-US',{minimumFractionDigits: minDigits} )
};

export const toNumericFormatBTC = (value: string | number) => {
    function formatToNumber(number: number | string) {
        return new Intl.NumberFormat('en-US').format(Number(number));
    }

    function separateLeadingZeros(input: string) {
        const chars = Array.from(input);
        const nonZeroIndex = chars.findIndex(char => char !== '0');
        if (nonZeroIndex === -1) {
            return { leadingZeros: input, remainingNumber: '' };
        }

        const leadingZeros = chars.slice(0, nonZeroIndex).join('');
        const remainingNumber = chars.slice(nonZeroIndex).join('');

        return { leadingZeros, remainingNumber };
    }

    const inputStr = String(value);

    const [integerPart, decimalPart] = inputStr.includes('e')
        ? parseFloat(inputStr).toFixed(20).split('.')
        : inputStr.split('.');

    if (!decimalPart) return formatToNumber(integerPart);

    const { leadingZeros, remainingNumber } = separateLeadingZeros(decimalPart);

    let roundedDecimal = remainingNumber;
    if (remainingNumber.length > 3) {
        const modifiedDecimal = remainingNumber.slice(0, 3) + '.' + remainingNumber.slice(3);
        roundedDecimal = Math.round(parseFloat(modifiedDecimal)).toString();
        if (roundedDecimal.endsWith('0')) {
            roundedDecimal = roundedDecimal.replace(/0+$/, '');
        }
    }

    return formatToNumber(integerPart) + '.' + leadingZeros + roundedDecimal;
}

export const toCurrencyFormat = (value: any, options: {minDigits: number, maxDigits: number} | null = null, emptyVal = '0') => {
    if (!value) {
        return emptyVal;
    }

    if(options && typeof options === "object"){
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: options.minDigits,
            maximumFractionDigits: options.maxDigits
        }).format(parseFloat(value));
    }

    return new Intl.NumberFormat('en-US').format(parseFloat(value));
};

export const toRoundNumber = (value: any) => {
    if (!value) {
        return 0;
    }
    return Math.round(parseFloat(value));
};

export const toFloorNumber = (value: any) => {
    if (!value) {
        return 0;
    }
    return Math.floor(parseFloat(value));
};


export const StringEllipsis = (str: string, n: number) => {
    if (!str) return '';
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
};

export const addAndPromisifyBatchCalls = (batchRequest: any, calls: any[]):
                                             {promises: any, promisesArray: Promise<any>[], resolvedValues: any} => {
    const resolvedValues: any = {};
    const promises: any = {};
    const promisesArray: any[] = [];
    calls.forEach((call: any) => {
        const promise = new Promise((resolve, reject) => {
            batchRequest.add(
                call.method.request((err: any, result: any) => {
                    if(err)
                        reject(err);
                    else
                        resolvedValues[call.name] = result;
                    resolve(result);
                })
            );
        });
        const key = call.name || '_';
        promises[key] = promise;
        promisesArray.push(promise);
    });
    return {
        promises,
        promisesArray,
        resolvedValues,
    };
};

export const fromWei = (value: string) => {
    if(!value) {
        return 0;
    }
    if (parseFloat(value) == 0) {
        return value;
    }
    return web3.utils.fromWei(value.toString());
};
export const toWei = (value: string, unit?: any) => {
    if(!value) {
        return 0;
    }
    if (parseFloat(value) == 0) {
        return value;
    }
    if (!unit || unit == 'wei') {
        value = parseFloat(value).toFixed(18);
    }
    return web3.utils.toWei(value.toString(), unit);
};
export const toBN = (value: any) => {
    if(parseInt(value) != value) {
        throw new Error("Value for convert BN must be an integer, got: "+value);
    }
    return web3.utils.toBN(value.toString());
};
/*
export const chartsAmountsToNumericFormat = (amount, minDigits = 2) => {
    if (minDigits === 4) {
        return numericFormatForTokensAndCharts(amount);
    } else {
        return toNumericFormat(amount);
    }
};
*/
export const numericFormatForTokensAndCharts = (value) => {
    if (!value) {
        return '0.0000';
    }
    return parseFloat(value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const makeRefferalLink = (referralId: string) => {
    return `${window.location.origin}/dashboard?ref=${referralId}`;
};
/*
export const capitilize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
*/
export const getClientRuntimeConfig = () => {
    if(process.client) {
        return (window as any).__NUXT__?.config ?? null;
    }
    return null;
};

export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}

