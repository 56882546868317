
import GYMButton from "~/components/utils/buttons/GYMButton";
import {mapGetters} from "vuex";
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import {isValidEmail} from "@/core/validators/email";
import {ACTIONS as AUTH_ACTIONS} from "@/store/affility/user/auth/names";

export default {
    name: "FinalizeRegistrationPopup",
    components: {GYMButton},
    emits: ['onPopupClose'],
    props: {
        popupVisible: Boolean,
    },
    watch: {
        popupVisible(visible) {
            if (visible) {
                this.userEmail = '';
                this.confirmationEmail = '';
                this.isGlobalTOSChecked = false;
                this.isAffiliateTOSChecked = false;
                this.isButtonDisabled = false;
            }
        },
        authUser: {
            async handler(user) {
                if (user) {
                    if (user.isGlobalTmsConfirmed) {
                        this.isGlobalTOSChecked = true;
                    }
                    if (user.isAffiliateTmsConfirmed) {
                        this.isAffiliateTOSChecked = true;
                    }
                    if (user.email) {
                        this.userEmail = user.email;
                    }
                }
            },
            deep: true
        }
    },
    data() {
        const REGISTRATION_TYPES = {
            WITH_CODE: 'withCode',
            WITHOUT_CODE: 'withoutCode'
        };

        return {
            REGISTRATION_TYPES,
            emailVerificationData: null,
            isGlobalTOSChecked: false,
            isAffiliateTOSChecked: false,
            userEmail: '',
            confirmationEmail: '',
            sponsorInfo: null,
            isButtonDisabled: false
        }
    },
    computed: {
        ...mapGetters('application/driver', ['authUser'])
    },
    methods: {
        handleModalClose() {
            this.$store.dispatch(`affility/user/auth/${AUTH_ACTIONS.LOG_OUT}`);
            this.$emit('onPopupClose');
        },
        async validateEmail(email) {
            let locIsValid = isValidEmail(email);
            if (locIsValid) {
                let isExists = await MetaWorldManager.sharedInstance().validateIsCredentialExists({email});
                if (isExists) {
                    this.emailVerificationData = {
                        isValid: false,
                        message: 'Email already in use'
                    };
                } else if (email !== this.confirmationEmail) {
                    this.emailVerificationData = {
                        isValid: false,
                        message: "Emails don't match",
                    }
                } else {
                    this.emailVerificationData = {
                        isValid: true,
                        message: 'Email is valid'
                    }
                }
            } else {
                this.emailVerificationData = {
                    isValid: false,
                    message: 'Please enter valid email'
                };
            }
        },
        async validateConfirmationEmail(email) {
            this.validateEmail(this.userEmail);
            if(this.emailVerificationData.isValid) {
                if(email !== this.userEmail) {
                    this.emailVerificationData = {
                        isValid: false,
                        message: "Emails don't match",
                    };
                } else {
                    this.emailVerificationData = {
                        isValid: true,
                        message: "Email is valid",
                    }
                }
            }
        },
        async handleFinalizeUserRegistration() {
            let payload = {
                email: this.userEmail,
                is_global_tms_confirmed: this.isGlobalTOSChecked,
                is_affiliate_tms_confirmed: this.isAffiliateTOSChecked,
            };
            if (this.authUser.email) {
                delete payload.email;
            }

            this.isButtonDisabled = true;

            await this.$store.dispatch('application/driver/userUpdate', payload).finally(() => {
                this.isButtonDisabled = false;
                this.$emit('onPopupClose');
            });
        },
        showGlobalTOSPopup(isChecked) {
            this.isGlobalTOSChecked = !this.isGlobalTOSChecked;
            if (isChecked) {
                this.$store.dispatch(
                    `affility/user/flows/terms-and-conditions/openGeneralTermsAndConditions`,
                    {
                        acceptable: false,
                        contentProps: {
                            isForSignUp: false,
                            sponsorUsername: this.sponsorInfo?.code,
                        }
                    }
                );
            }
        },
        showAffiliateTOSPopup(isChecked) {
            this.isAffiliateTOSChecked = !this.isAffiliateTOSChecked;
            if (isChecked) {
                this.$store.dispatch(
                    `affility/user/flows/terms-and-conditions/openAffiliateTermsAndConditions`,
                    {
                        acceptable: false,
                    }
                );
            }
        },
    }
}
