import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { ACTIONS } from "./names";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "~/store/affility/user/feedback/modals/full-loader/names";
import { BROADCAST_EVENT_TYPES } from "~/core/services/utils/Constants";

export default {
    [ACTIONS.UPDATE_USER_AUTH_DATA]({ commit }, data: Record<string, unknown>) {
        const currentAuthUserData = this.$auth.user;
        this.$auth.$storage.setState('user', {
            ...currentAuthUserData,
            ...data,
        });
        commit("application/driver/UPDATE_AUTH_USER", {
            ...data,
        }, { root: true });
    },
    async [ACTIONS.LOG_OUT]({ commit, dispatch }, payload: { broadcast: boolean }) {
        await dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.OPEN_FULL_PAGE_LOADING}`,
            null, {root: true});
        await this.$auth.logout();
        await dispatch('application/driver/logOut', null, {root: true});
        await this.$router.push('/shop');
        if (!payload?.broadcast && GlobalMakerService.$broadcast) {
            GlobalMakerService.$broadcast.postMessage({
                type: BROADCAST_EVENT_TYPES.STORE_DISPATCH,
                action: `affility/user/auth/${ACTIONS.LOG_OUT}`,
                payload: {},
            });
        }
        await dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.CLOSE_FULL_PAGE_LOADING}`, {
            delay: 1000,
        }, {root: true});
    },
};
