import ApiService from "../../api-interaction/ApiService";
import { METAVERSES } from "../../utils/MetaverseConstants";
import { requestAuthorizationHeaderRemover } from "../helpers/requestAuthorizationHeaderRemover";
import { LandBoundaries } from "../types/LandBoundaries";
import { RawLandBoundaries } from "../types/RawLandBoundaries";
import { LandBoundariesProcessingResult } from "../types/LandBoundariesProcessingResult";
import { MapBuilder } from "../MapBuilder";
import { MapCreationStep } from "./MapCreationStep";

export class MapBoundariesStep extends MapCreationStep {
    
    constructor (
        metaverseId: number,
        private map: MapBuilder,
        nextStep?: MapCreationStep,
    ) {
        super(metaverseId, nextStep);
    }

    protected async loadFromAPI (): Promise<RawLandBoundaries | null> {
        const rawBoundariesResponse = await ApiService.query(
            METAVERSES[this.metaverseId].files[0],
            {
                transformRequest: [requestAuthorizationHeaderRemover],
            });
        return rawBoundariesResponse.data as RawLandBoundaries || null;
    }

    protected async loadFromIndexedDB (): Promise<RawLandBoundaries> {
        const idb = await MapCreationStep.idb;
        const result = await idb.get('map-boundaries', this.metaverseId);
        return (result?.data as RawLandBoundaries) || null;
    }

    protected process (rawBoundaries: RawLandBoundaries): LandBoundariesProcessingResult {
        const entries = Object.entries(rawBoundaries);
        const processedBoundaries: LandBoundaries = new Array(entries.length);
        let matrixMaxX = -1;
        for (const [row, cols] of entries) {
            const rowBounds = [];
            for (const col of cols) {
                rowBounds.push(new Uint16Array([col.min, col.max]));
                if (col.max > matrixMaxX) {
                    matrixMaxX = col.max;
                }
            }
            processedBoundaries[Number(row)] = rowBounds;
        }
        return {
            processedBoundaries,
            matrixSize: {
                width: matrixMaxX + 1,
                height: entries.length,
            },
        };
    }

    protected setupOnMap (data: LandBoundariesProcessingResult): void {
        this.map.createMatrix(data);
        this.map.redraw();
    }

    protected async save (data: RawLandBoundaries): Promise<IDBValidKey> {
        const idb = await MapCreationStep.idb;
        return idb.put('map-boundaries', {
            metaverseId: this.metaverseId,
            data,
        });
    }

    public async restoreFromIndexedDB (): Promise<boolean> {
        const rawBoundaries = await this.loadFromIndexedDB();
        if (rawBoundaries) {
            const processedBoundaries = this.process(rawBoundaries);
            this.setupOnMap(processedBoundaries);
        }
        return !!rawBoundaries;
    }

    public async createFromAPI (): Promise<void> {
        const rawBoundaries = await this.loadFromAPI();
        const processedBoundaries = this.process(rawBoundaries);
        this.setupOnMap(processedBoundaries);
        this.save(rawBoundaries);
    }

    public async executeSelf(): Promise<void> {
        const restored = await this.restoreFromIndexedDB();
        if (!restored) {
            await this.createFromAPI();
        }
    }
}