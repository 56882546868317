import { render, staticRenderFns } from "./PurchaseCore.vue?vue&type=template&id=0d43c415&scoped=true"
import script from "./PurchaseCore.vue?vue&type=script&lang=ts&setup=true"
export * from "./PurchaseCore.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PurchaseCore.vue?vue&type=style&index=0&id=0d43c415&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d43c415",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLoaderAffility: require('/root/build_gs/core/components/base/loader/Affility.vue').default,FlowsPurchaseComponentsPurchaseSummaryProductImage: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/ProductImage.vue').default,FlowsPurchaseComponentsPurchaseSummaryTotal: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/Total.vue').default,FlowsPurchaseComponentsPurchaseSummaryInfoList: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/InfoList.vue').default,BaseTextInfoMessage: require('/root/build_gs/core/components/base/text/InfoMessage.vue').default,BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
