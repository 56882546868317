export const STATE = {
    RAFFLE_TICKETS: 'raffleTickets',
    RAFFLE_TICKETS_LOADING: 'raffleTicketsLoading',
    RAFFLE_WINNERS: 'raffleWinners',
    RAFFLE_ACTIVE_STATUS: 'raffleActivateStatus',
} as const;

export const ACTIONS = {
    GET_RAFFLE_TICKETS: 'getRaffleTickets',
    ON_RAFFLE_TICKETS_LOADING: 'onRaffleTicketsLoading',
    GET_RAFFLE_WINNERS: 'getRaffleWinners',
    ON_RAFFLE_ACTIVE_STATUS: 'onRaffleActivateStatus',
    ON_SAVE_RAFFLE_TICKETS_POPUP: 'onSaveRaffleTicketsPopup',
} as const;

export const MUTATIONS = {
    SET_RAFFLE_TICKETS: 'setRaffleTickets',
    SET_RAFFLE_TICKETS_LOADING: 'setRaffleTicketsLoading',
    SET_RAFFLE_WINNERS: 'setRaffleWinners',
    SET_RAFFLE_ACTIVE_STATUS: 'setRaffleActivateStatus',
} as const;

export const GETTERS = {
    RAFFLE_TICKETS: 'raffleTickets',
    RAFFLE_TICKETS_LOADING: 'raffleTicketsLoading',
    RAFFLE_CONGRATS_POPUP_VISIBLE: 'raffleCongratsPopupVisible',
    RAFFLE_WINNERS: 'raffleWinners',
    RAFFLE_ACTIVE_STATUS: 'raffleActivateStatus',
    IS_RAFFLE_ACTIVE: 'isRaffleActivate',
    IS_STREAM_STARTED: 'isStreamStarted',
    IS_STREAM_ENDED: 'isStreamEnded',
} as const;
