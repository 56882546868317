import { STATE } from "./names";
import { RaffleStatusType, RaffleTicket } from "~/store/affility/promos/raffle/types";

export default () => {
    return {
        [STATE.RAFFLE_TICKETS]: [] as RaffleTicket[],
        [STATE.RAFFLE_TICKETS_LOADING]: true,
        [STATE.RAFFLE_WINNERS]: [] as string[],
        [STATE.RAFFLE_ACTIVE_STATUS]: {
            isActive: false,
            isStreamStarted: false,
            isStreamEnded: false,
        } as RaffleStatusType,
    };
};
